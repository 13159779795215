@use "../../scss/abstracts/" as *;
@use "sass:math";
// ul {
//   max-height: 0;
//   transition: max-height 0.5s;

//   &.show {
//     max-height: 250px;
//   }

//   li {
//     &:hover {
//       background: #efefef;
//     }

//     &:focus {
//       background: #ffffff;
//     }
//   }
// }

.selectable-badge-dropdown {
  margin-top: 10px;
  padding: 10px;
  position: absolute;
  width: 150px;
  z-index: 999999;
  border-radius: 15px !important;
  left: 15px;
  background: lighten($mainBg, 25);
  box-shadow: inset 0px 4px 6px 0px darken($mainBg, 15);
  border-color: $mainBg;
  li {
    &:hover {
      transform: scale(1.1);
    }
  }
  .key-102 {
    color: darken($orange, 5) !important;
  }
  .key-101,
  .key-104,
  .key-106 {
    color: darken($red, 5) !important;
  }
  .key-100,
  .key-103,
  .key-105 {
    color: darken($primaryColor, 5) !important;
  }
}
