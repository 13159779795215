@use "../../scss/abstracts/"as *;
@use "sass:math";

.card-box {
  background: #f5f5f5;
  box-shadow: 5px 10px 60px rgba(81, 180, 210, 0.36);
  border-radius: 40px;
  padding: 25px;
  color: $white;
  position: relative;
  @media (max-width: 576px) {
    border-radius: 30px;
    padding: 15px;
  }
  &:after {
    content: "";
    position: absolute;
    width: 365px;
    height: 165px;
    background: url(../../assets/images/courbe.svg) no-repeat;
    bottom: -85px;
    right: -30px;
  }

  .box-inner-card {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    h4 {
      color: $white;
      @media (max-width: 576px) {
       margin-bottom: 0;
      }
    }
  }

  .card-icon {
    background: rgba(255, 255, 255, 0.35);
    box-shadow: -5px 5px 30px rgb(0, 0, 0, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.63);
    border-radius: 25px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1350px) {
      width: 60px;
      height: 60px;
      border-radius: 20px;
      img {
        max-width: 50px; 
      }
    }
  }

  .card-texte {
    width: calc(100% - 120px);
    padding-left: 20px;
    @media (max-width: 1350px) {
      width: calc(100% - 60px);
      p{
        margin-bottom: 0;
      }
    }
  }

  .card-price {
    font-size: 1.4rem;
    line-height: 30px;
    font-family: $tt-demibold;
  }

  &.card-primary {
    background-image: linear-gradient(to right,
        #62d1ba,
        #53c6ae,
        #44bca2,
        #34b196,
        #1fa78a);
  }

  &.card-danger {
    background-image: linear-gradient(to right,
        #f68578,
        #f57465,
        #f36351,
        #ef503c,
        #eb3a27);
  }

  &.card-warning {
    background-image: linear-gradient(to right,
        #ffcc54,
        #fec24d,
        #feb946,
        #fdaf40,
        #fca53b);
  }

  &.card-purple {
    background-image: linear-gradient(to right,
        #795be0,
        #886ce7,
        #967cee,
        #a58df5,
        #b39efb);
  }

  &.card-secondary {
    background-image: linear-gradient(to right,
        #4fadd4,
        #46a5cc,
        #3d9cc4,
        #3494bd,
        #2a8cb5);
  }
}