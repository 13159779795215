@use "../../../scss/abstracts/"as *;

.section-Iban-paie {
  padding: 25px 0 15px;

  h3 {
    color: $black;
    text-transform: uppercase;
    font-family: $tt-demibold;
    font-size: 27px;
    margin-bottom: 10px;
  }
  .link {
    color: $secondaryColor
  }

  @media (min-width: 768px) and (max-width: 991px) {
    label.form-label {
      max-width: 190px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
}

.bottom-actions {
  .btn {
    margin: 5px;
  }
}

.text-nb {
  color: #b0b0b0;

  p {
    line-height: 20px;
  }


}