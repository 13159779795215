@use "../../scss/abstracts/" as *;

.topnav {
  .create-button {
    svg {
      path {
        stroke: $white !important;
      }
    }
    @media (max-width: 991px) {
      position: absolute;
      top:98px;
      z-index: 99;
    }
    @media (max-width: 420px) { 
      top:88px;
    }
  }

  @include flex(center, flex-end);
  margin-bottom: $spacing;

  @include tablet {
    margin-bottom: $mobileSpacing;
  }
  @media (max-width: 991px)   {
    background: #fff;
    padding: 15px;
    margin: 0 -15px 35px;
    box-shadow: 0 2px 10px rgba(0,0,0,.12);
  }
}

.ticket-creation-textarea {
  border-radius: 0 35px 35px 35px !important;
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 20px;
}

.notification-item:hover {
  background-color: $bodyBg;
}

.notification-item > i {
  margin-right: 20px;
  font-size: 1.5rem;
}

.topnav__right-user {
  display: flex;
  align-items: center;
}

.topnav__right-user__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.topnav__right-user__image > img {
  width: 100%;
}

.topnav__right-user__name {
  font-size: 1rem;
  font-weight: 600;
}

.topnav__right-item {
  &.notif-drop-wrapper {
    visibility: hidden;
  }
  margin-left: 10px;
}
