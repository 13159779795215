@use "../../scss/abstracts/"as *;

.section-bilan {

  h2 {
    color: #000;
    text-transform: uppercase;
    font-family: "tt_commonsdemibold";
    font-size: 30px;
    margin-bottom: 10px;
    text-align: center;
  }

  .form-bilan {
    padding: 15px 50px;

    @media (max-width: 991px) {
      padding: 15px 0;
    }
  }

  .box-bilan-item {
    padding-bottom: 15px;

    h3 {
      color: $secondaryColor;
      border-bottom: 2px dashed $secondaryColor;
      font-size: 25px;
      margin-bottom: 30px;
      font-family: "tt_commonsdemibold";

      .required-file {
        color: $red;
        font-size: 0.95rem;
      }
      @media (max-width: 991px) {
        font-size: 20px;
        padding-bottom: 5px;
      }
    }

    .recap-creation {
      color: $black;
      font-size: 0.95rem;
    }

    .recap-error {
      color: $red;
      font-size: 0.95rem;
    }

    .file-viewer {
      cursor: pointer;
      &.file-box-item {
        font-size: 1rem;

        span {
          text-decoration: underline;
          max-width: 150px;
        }

        svg {
          margin: 0 5px;
          color: $secondaryColor;
          font-size: 1.2rem;
        }
      }
    }
  }

  .noly-compta-dilan {
    box-shadow: inset 0px 4px 6px 0px #bae0f1;
    border: 1px solid #50b1dc;
    border-radius: 40px;
    padding: 40px 50px;

    .box-bilan-item {
      border-top: 2px dashed $secondaryColor;
      padding: 25px 0;
    }

    h3 {
      border: none;
      margin-bottom: 15px;
    }

    @media (max-width: 767px) {
      padding: 20px 15px;
    }
  }

  .bottom-actions {
    .btn {
      min-width: 200px;
    }
  }
}

.progress-bilan {
  padding: 0 0 15px;
  overflow: hidden;
  max-width: 800px;
  width: 100%;
  margin: 0 auto 15px;

  h3 {
    font-size: 25px;
    font-family: $tt-medium;
    color: $orange;
    text-align: center;
    margin-bottom: 25px;
  }

  .progress {
    display: flex;
    height: 35px;
    overflow: visible;
    font-size: 0.75rem;
    background-color: #fff;
    border-radius: 60px;
    box-shadow: inset 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid #b4b4b4;
  }

  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: visible;
    color: #fff;
    text-align: right;
    white-space: nowrap;
    background: $orange;
    background-image: linear-gradient(to right,
        #ffb254,
        #fda743,
        #fc9c32,
        #fa911f,
        #f98500);
    border-radius: 60px;
    transition: width 0.6s ease;
    position: relative;
  }

  .value-bilan {
    font-size: 20px;
    font-family: $tt-demibold;
    color: $orange;
    position: absolute;
    right: 0;
    width: 55px;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);

    span {
      position: relative;
      z-index: 9;
      line-height: 50px;
      width: 55px;
      text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.18);
    }

    &::after {
      content: "";
      position: absolute;
      font-size: 25px;
      color: $orange;
      height: 50px;
      width: 50px;
      right: 0;
      transform: rotate(45deg);
      line-height: 33px;
      color: #fff;
      background: #fff;
      box-shadow: inset 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
      border: 1px solid $orange;
      border-radius: 50% 50% 50% 0;
      z-index: 0;
    }
  }
}

.bilan-year-select{
  .custom-select{
    .select__indicator{
      svg {
        fill: $secondaryColor;
      }
    }
  }
}

.bilan-selector-wrapper{
  .Banner-img{
    img{
      height: 95%;
      width: auto;
    }
  }
}

.bilan-admin-file-ready{
  cursor: pointer;
  color: $secondaryColor;
  text-decoration: underline;
}