@use "../../../scss/abstracts/" as *;
@media (min-width: 576px) {
  .pay-diff-modal {
    max-width: 1450px !important;
    .content-form-block {
      padding: 15px 0;
    }
  }
}
.file-input {
  display: none;
}

.card-Table {
  position: relative;
  margin: 0 0 50px;

  .bottom-pagination {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -90px;
  }
  @media (max-width: 557px) {
    margin: 0 0 40px;
  }
}

.file-table {
  .form-file {
    padding: 0;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 5px !important;

    .file-box {
      border-radius: 20px;
      padding: 5px 10px;
      max-width: 150px;

      span {
        font-size: 0.85rem;
      }
    }
  }

  .file-box-item {
    color: #000 !important;
    background: transparent;
    font-size: 0.85rem;
    font-family: $tt-medium;
    padding: 5px 10px 0;
    box-shadow: none;
    height: auto;
  }

  .list-Files {
    margin-top: 0 !important;
  }
}

.top-chois-form {
  padding: 15px 0;
}

.left-label {
  text-align: center;
  color: $primaryColor;
}

.list-encaissements-choix {
  padding: 15px 0;

  .choix-item {
    font-size: 1.3rem;
    color: $black;
    border-radius: 50px;
    padding: 10px 15px;
    min-height: 60px;
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    transition: all 0.3s;
    position: relative;
    left: 0;
    flex-direction: column;

    &:hover,
    &:focus,
    &.selected {
      border-color: $primaryColor;
      color: $primaryColor;
      transition: all 0.3s;
    }

    &:hover {
      left: -10px;
    }
    p {
      margin-bottom: 0;
    }
  }
}

.tr-categ-cell-wrapper {
  p {
    margin-bottom: 0;
  }
  .tr-categ-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 0.9rem;
      font-weight: 600;
      &:hover {
        transform: scale(1.1);
      }
      svg {
        fill: $primaryColor;
        width: 30px;
      }
    }
    .tr-categ-cell-toCateg {
      border-radius: 15px;
      padding: 0 5px 0 15px;
      border: 1px dashed $primaryColor;
    }
  }
}

.tr-date-cell-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .date-update-form {
    border: 1px solid $secondaryColor;
    height: 50px;
    max-width: 150px;
  }

  .tr-date-cell,
  .tr-edit-date-cell {
    font-weight: 600;
    padding: 0 5px;
    svg {
      fill: $secondaryColor;
    }
    .btn-blue {
      background: $secondaryColor;
      color: $white;
    }
  }
}

.remunerations-block {
  background: lighten($primaryColor, 35);
  border-radius: 30px;
  overflow: hidden;
  margin: 15px 15px 0 0;
  @media (max-width: 767px) {
    margin: 15px 0 0;
  }
  ul {
    padding: 3px 5px;
    margin-bottom: 0;

    li {
      padding: 0 0 1px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        background: lighten($primaryColor, 20);
        left: 25px;
        right: 25px;
        bottom: 0;
        z-index: 0;
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      .name-cat-span {
        text-align: center;
        font-family: $tt-medium;
        padding: 12px 15px;
        color: $primaryColor;
        border-radius: 30px;
        font-size: 1.2rem;
        display: block;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        position: relative;
        z-index: 1;
      }

      &.active,
      &:hover {
        .name-cat-span {
          background: lighten($primaryColor, 5);
          box-shadow: inset 0px 4px 6px 0px darken($primaryColor, 15);
          border-color: $primaryColor;
          font-size: 1.2rem;
          color: $white;
          transition: all 0.2s ease-in-out;
        }
        &:after {
          opacity: 0;
          transition: all 0.2s ease-in-out;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.red-num {
  color: $red;
  font-size: 1.13rem;
  font-family: $tt-medium;
}

.green-num {
  color: $green;
  font-size: 1.3rem;
  font-family: $tt-medium;
}

.category-selection-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .category-selection-button {
    &.btn-outline-primary {
      padding: 2px 15px;
      min-height: 50px;
    }
  }
}

.file-table {
  max-width: 100%;

  .transaction-file {
    width: 150px;
    text-align: center;
    justify-content: center;

    span {
      margin: 0 5px;
      cursor: pointer;
    }
    svg {
      width: 30px;
    }
  }
}

.asset-type-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  svg {
    color: $secondaryColor;
  }

  span {
    font-weight: 400;
    line-height: 1.5;
    color: $secondaryColor;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    padding: 0.75rem;
    font-size: 1rem;
  }
}

.transaction-file {
  display: flex;
}

.toValide-transactions-table {
  .rdt_TableCell {
    min-width: 175px;
  }
  [data-column-id="code-cell-wrapper"] {
    max-width: 40px;
    min-width: 40px;
    width: 40px;
    font-weight: bold;
    color: $orange;
  }
}

.carb-modal {
  .carb-modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .card {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 15px;
      box-shadow: inset 0px 4px 6px 0px lighten($default, 20);
      button {
        margin-top: 15px;
        width: 65%;
      }
      img {
        height: 180px;
      }
      &.util-veh-card {
        box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 20);
        img {
          max-width: 270px;
        }
      }
      &.tour-veh-card {
        box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 20);
        img {
          max-width: 230px;
        }
      }
    }
  }
  .carb-modal-nb {
    padding: 48px 48px 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-col {
      width: 10%;
      text-align: center;
      svg {
        height: 70%;
        width: 70%;
        stroke: $orange;
      }
    }
    .text-col {
      width: 90%;
      svg {
        height: 100%;
        width: auto;
        stroke: $orange;
      }
    }
  }
}

.tva-choice-upper {
  display: flex;
  align-items: center;
  justify-content: center;
  .custom-select {
    width: 30%;
    margin: 0 10px;
  }
}

.table-tva {
  overflow: inherit;
  min-height: 250px !important;
  table {
    th,
    td {
      text-align: center;
    }
    td.tva-action {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .input-device-left {
      input {
        text-align: center;
      }
      .devise {
        right: 35px;
      }
    }
    .btn {
      margin: 0 5px;
    }
  }
}
