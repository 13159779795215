@use "./variables" as *;
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    line-height: 25px;
    font-size: 1.1rem;
    font-family: $tt-medium;
    @media (max-width: 576px) {
      line-height: 16px;
      font-size: 1rem;
      padding-left: 35px;
    }
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 25px;
    height: 25px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
    box-shadow: inset 0px 4px 5px 0px lighten($black, 85);
    @media (max-width: 576px) {
      position: absolute;
      left: 0;
      top:3px;
    }
  }

  // Box checked
  &:checked + label:before {
    background: $primaryColor;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 13px;
    background: white;
    width: 3px;
    height: 3px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
.check-primary {
  .styled-checkbox {
    & + label:before {
      border: 1px solid $primaryColor;
    }

    // Box checked
    &:checked + label:before {
      background: $primaryColor;
      box-shadow: inset 0px 4px 5px 0px darken($primaryColor, 8);
    }
  }
}
.check-secondary {
  .styled-checkbox {
    & + label:before {
      border: 1px solid $secondaryColor;
    }

    // Box checked
    &:checked + label:before {
      background: $secondaryColor;
      box-shadow: inset 0px 4px 5px 0px darken($secondaryColor, 8);
    }
  }
}
.check-warning {
  .styled-checkbox {
    & + label:before {
      border: 1px solid $orange;
    }

    // Box checked
    &:checked + label:before {
      background: $orange;
      box-shadow: inset 0px 4px 5px 0px darken($orange, 8);
    }
  }
}
.check-danger {
  .styled-checkbox {
    & + label:before {
      border: 1px solid $red;
    }

    // Box checked
    &:checked + label:before {
      background: $red;
      box-shadow: inset 0px 4px 5px 0px darken($red, 8);
    }
  }
}
