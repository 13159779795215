@font-face {
  font-family: "tt_commonsblack_italic";
  src: url("../../assets/fonts/tt_commons_black_italic-webfont.woff2")
      format("woff2"),
    url("../../assets/fonts/tt_commons_black_italic-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_commonsdemibold";
  src: url("../../assets/fonts/tt_commons_demibold-webfont.woff2")
      format("woff2"),
    url("../../assets/fonts/tt_commons_demibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_commonsmedium";
  src: url("../../assets/fonts/tt_commons_medium-webfont.woff2") format("woff2"),
    url("../../assets/fonts/tt_commons_medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_commonslight";
  src: url("../../assets/fonts/tt_commons_light-webfont.woff2") format("woff2"),
    url("../../assets/fonts/tt_commons_light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_commonsregular";
  src: url("../../assets/fonts/tt_commons_regular-webfont.woff2")
      format("woff2"),
    url("../../assets/fonts/tt_commons_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_commonsthin";
  src: url("../../assets/fonts/tt_commons_thin-webfont.woff2") format("woff2"),
    url("../../assets/fonts/tt_commons_thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_commonsextrabold";
  src: url("../../assets/fonts/tt_commons_extrabold-webfont.woff2")
      format("woff2"),
    url("../../assets/fonts/tt_commons_extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tt_commonsbold";
  src: url("../../assets/fonts/tt_commons_bold-webfont.woff2") format("woff2"),
    url("../../assets/fonts/tt_commons_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
