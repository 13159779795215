@use "../../scss/abstracts/" as *;

.first-sync-comp {
  border: 2px solid $secondaryColor;
  background-color: $white;
  font-weight: 900;
  font-size: 1.5rem;
  box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
  border-color: $secondaryColor;
  border-radius: 35px;
  padding: 15px;
  margin: 1.2rem 0;
  .first-sync-text,.first-sync-img{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;
  }
  img{
    max-width: 60%;
    width: 50%;
    height: auto;
  }

}
