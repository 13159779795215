@use "../../scss/abstracts/" as *;

.actionsFilter {
  display: flex;

  .btn {
    margin: 0 5px;
    flex: 1 0;
  }
}

.action-top-nav {
  position: absolute;
  top: -82px;
  right: 75px;

  .btn {
    margin: 0 7px;
  }
  @media (max-width: 991px) {
    position: static;
    text-align: right;
    .btn {
      margin: 0 5px 12px;
    }
  }
}

.btn-creat-user {
  min-width: 240px;
  min-height: 54px !important;

  svg,
  img {
    margin: 0 15px 0 0 !important;
    max-width: 28px !important;
  }
}

.btn-creat-lead {
  min-width: 200px;
  min-height: 54px !important;

  svg,
  img {
    margin: 0 15px 0 0 !important;
    max-width: 28px !important;
  }
}

.actionsFilter {
  display: flex;

  .btn {
    margin: 0 5px;
    flex: 1 0;
  }

  @media (max-width: 991px) {
    padding: 30px 0 10px;
    justify-content: center;

    .btn {
      flex: none;
      min-width: 120px;
    }
  }
}

.ticket-profile-wrapper{
  height: 100%;
}

.ticket-id {
  display: flex;
  align-items: center;
}

.message-badge {
  border-radius: 50%;
  margin-left: 5px;
}

// test css, you can delete later

.chat-window {
  width: 100%;
  height: 420px;

  p {
    margin: 0;
  }

  .chat-header {
    height: 45px;
    border-radius: 6px;
    background: #263238;
    position: relative;
    cursor: pointer;

    p {
      display: block;
      padding: 0 1em 0 2em;
      color: #fff;
      font-weight: 700;
      line-height: 45px;
    }
  }

  .chat-body {
    height: 400px;
    border: 1px solid #263238;
    background: #fff;
    position: relative;
    padding: 5px;
    border-radius: 5px;

    .message-container {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .message {
      height: auto;
      padding: 10px;
      display: flex;

      .message-content {
        width: auto;
        height: auto;
        min-height: 50px;
        max-width: 250px;
        background-color: #43a047;
        border-radius: 5px;
        color: white;
        display: flex;
        align-items: center;
        margin-right: 5px;
        margin-left: 5px;
        padding-right: 5px;
        padding-left: 5px;
        overflow-wrap: break-word;
        word-break: break-word;
      }

      .message-meta {
        display: flex;
        font-size: 12px;
      }
    }
  }

  .chat-footer {
    height: 40px;
    border: 1px solid #263238;
    border-top: none;
    display: flex;

    input {
      height: 100%;
      flex: 85%;
      border: 0;
      padding: 0 0.7em;
      font-size: 1em;
      border-right: 1px dotted #607d8b;
      outline: none;
      font-family: "Open Sans", sans-serif;
    }

    button {
      border: 0;
      display: grid;
      place-items: center;
      cursor: pointer;
      flex: 15%;
      height: 100%;
      background: transparent;
      outline: none;
      font-size: 25px;
      color: lightgray;

      &:hover {
        color: #43a047;
      }
    }
  }
}

#you {
  justify-content: flex-start;

  .message-content {
    justify-content: flex-start;
  }

  .message-meta {
    justify-content: flex-start;
    margin-left: 5px;
  }
}

#other {
  justify-content: flex-end;

  .message-content {
    justify-content: flex-start;
    background-color: cornflowerblue;
  }

  .message-meta {
    justify-content: flex-end;
    margin-right: 5px;
  }
}

.message-meta {
  #author {
    margin-left: 10px;
    font-weight: bold;
  }
}

.hide {
  opacity: 0 !important;
}
.page-tickets{
  @media (max-width: 991px) {
    padding: 50px 0 0;
  }
}