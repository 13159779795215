@use "./variables" as *;
input[type="color"] {
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  border: 0;
  border-radius: 50%;
  padding: 0;
  overflow: hidden;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}

.form-color {
  border: 1px solid lighten($black, 85);
  color: #000;
  box-shadow: inset 0px 4px 5px 0px lighten($black, 85);
  border-radius: 35px;
  min-height: 30px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    display: inline-block;
    margin: 0 0 0 5px;
    color: $black;
    font-family: $tt-medium;
    font-size: 14px;
    line-height: 25px;
    &.icon-pep {
      img {
        max-width: 22px;
        position: relative;
        bottom: -5px;
      }
    }
  }

  @media (max-width: 576px) {
    justify-content: space-between;
  }
}
