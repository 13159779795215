@use "../../../scss/abstracts/" as *;
@use "sass:math";

.legende-box {
  padding: 0 0 0 15x;
}
.legende-item {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin: 2px 5px;
  &.primary {
    color: #00acbe;
    &::before {
      border-color: #00acbe;
    }
  }
  &.danger {
    color: #fd6c80;
    &::before {
      border-color: #fd6c80;
    }
  }
  &::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border: 3px solid transparent;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.Link {
  text-decoration: underline;
  color: white;
  text-underline-offset: 1px;
}
@media (max-width: 1199px) {
  .Banner h2 {
    font-size: 2rem;
    line-height: 30px;
  }
}
@media (max-width: 950px) {
  .More {
    margin-top: 8px;
    margin-bottom: 4px;
    margin-left: 0px;
  }
  
}


 
