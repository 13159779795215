@use "./variables" as *;

.quill {
  border-radius: 35px;
  overflow: hidden;
  .ql-toolbar.ql-snow:first-child {
    // display: none;
    // it's okey i know leave it
    display: block;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: none;
    min-height: 150px;
  }
  .ql-toolbar.ql-snow {
    border: none;
    padding: 14px 10px;
  }
  &.form-primary {
    background: lighten($primaryColor, 25);
    box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 15);
    border-color: $primaryColor;

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      background: lighten($primaryColor, 35);
    }
  }
  &.form-seconadry {
    background: lighten($secondaryColor, 25);
    box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 15);
    border-color: $secondaryColor;

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      background: lighten($secondaryColor, 35);
    }
  }
  &.form-warning {
    background: lighten($orange, 25);
    box-shadow: inset 0px 4px 6px 0px lighten($orange, 15);
    border-color: $orange;

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      background: lighten($orange, 30);
    }
  }
  &.form-danger {
    background: lighten($red, 25);
    box-shadow: inset 0px 4px 6px 0px lighten($red, 15);
    border-color: $red;

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
      background: lighten($red, 35);
    }
  }
}
