@use "../../scss/abstracts/" as *;

.section-dashboard {
  position: relative;

  &.add-dashboard-padding {
    padding: 45px 0 0;
  }


  @media (max-width: 576px) {
    padding: 25px 15px 0;
  }
  @media (max-width: 450px) {
    padding: 10px 2px 0;
  }
}

.dashboard-selector-wrapper {
  .Banner-img {
    img {
      height: 95% !important;
      width: auto;
    }
  }
}

.card-item-dash {
  box-shadow: -5px 5px 50px lighten($primaryColor, 30);
  padding: 25px;

  h3 {
    font-size: 1.5rem;
    line-height: 28px;
    margin-bottom: 0;
    font-family: $tt-demibold;
    color: $black;
  }
  @media (max-width: 576px) {
    padding: 20px 15px 5px;
    h3 {
      font-size: 1.3rem;
      line-height: 22px;
    }
  }
}

.title-dash-card {
  display: flex;
  justify-content: space-between;
  padding: 0 0 25px;

  @media (max-width: 767px) {
    flex-direction: column;

    .legende-box {
      text-align: right;
    }
  }
}

.action-top-dash {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 15px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start; 

    .dash-wrapper {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 10px 0 0;
    }
  }
  @media (max-width: 576px) { 
    align-items: center;
    .dash-wrapper { 
      justify-content: center; 
      flex-wrap: wrap;
      margin: 0 -5px;
      .btn{
        margin: 5px;
        min-width: 120px;
        padding: 0 15px;
      }
    }
  }
  .month-label {
    font-size: 1.5rem;
    font-family: $tt-demibold;
    color: #000;
    text-transform: uppercase;
  }

  .btn {
    margin-right: 15px;
    min-width: 185px;
    min-height: 50px;

    :first-child {
      margin-right: 0;
    }
  }
}

.chart-box {
  max-width: 350px;
  margin: 0 auto;
}

.full-height {
  display: flex;
  height: calc(100% - 30px);
  
  @media (max-width: 991px) {
    height: calc(100% - 15px);
  }
}

.price-solde {
  color: #000dc4;
  font-size: 2.5rem;
  line-height: 55px;
  font-family: $tt-bold;
  @media (max-width: 1350px) {
    font-size: 2.1rem;
    line-height: 45px;
  }
  @media (max-width: 576px) {
    font-size: 2rem;
    line-height: 45px;
  }
}

.ca-chart-loader{
  min-height: 543px;
}

.loading-chart{
  position: relative;
  opacity: 0.5;
  .chart-spinner{
    position: absolute;
    height: 5rem;
    width: 5rem;
    color: $secondaryColor;
    border-width: 0.5rem;
    left: 50%;
    top: 35%;
  }
}

.resize-card-blacklist{
  .etat-value-item{
    justify-content: start !important;
    padding: 10px 0 0 0 !important;
  }
}