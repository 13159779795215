@use "../../scss/abstracts/" as *;

.user-info {
  @include flex(center, center);
  background-color: $mainBg;
  border-radius: $borderRadius;
  height: 100%;
  width: 190px;
  position: relative;
  &.client-profile-pic{
    img {
      max-width: 160px;
      max-height: 160px;
      object-fit: contain !important;
      transform: scale(1.5);
    }
  }
  // background: url(../../assets/images/cercle-bg.png) no-repeat;
  .bgCercle {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 991px) {
      display: none;
     }
  }
  @media (max-width: 1450px) {
    height: 140px;
    width: 140px;
  }
  @media (max-width: 991px) {
    height: 52px;
    width: 52px !important;
    border-radius: 10px;
  }
  @media (max-width: 420px) {
    height: 40px;
    width: 40px !important;
  }
  &__img {
    --size: 160px;
    max-width: var(--size);
    // height: auto;
    // width: 100%;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    overflow: hidden;

    img {
      max-width: 160px;
      max-height: 160px;
      width: 160px;
      height: 160px;
      object-fit: cover;
    }
    @media (max-width: 1450px) {
      height: 125px;
      width: 125px;
      img {
        max-width: 125px;
        max-height: 125px;
        height: 125px;
        width: 125px;
      }
    }

    @media (max-width: 991px) {
      height: 52px !important;
      width: 52px !important;
      border: 2px solid $primaryColor;
      border-radius: 10px;
      img {
        max-width: 52px !important;
        max-height: 52px !important;
        height: 52px !important;
      width: 52px !important;
      }
    }
    @media (max-width: 420px) {
      height: 40px !important;
      width: 40px !important;
      img {
        max-width: 40px !important;
        max-height: 40px !important;
        height: 40px !important;
        width: 40px !important;
      }
    }
  }

  &__name {
    padding: 0 $spacing;
    font-size: $fontSizeMedium;
    font-weight: 700;

    @include tablet {
      padding: 0 $mobileSpacing;
    }
  }
}
.clickable-profile {
  cursor: pointer;
}
.blockUser {
  .btn.btn-mailing {
    &:hover {
      svg path {
        fill: #fff !important;
        stroke: none !important;
      }
    }
  }
}
