@use "../../../scss/abstracts/" as *;

.section-information-societe {
  padding: 25px 0 15px;
  h3 {
    color: #b0b0b0;
    border-bottom: 2px dashed #b0b0b0;
    font-size: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 1550px) {
    .form-file {
      padding: 20px !important;
      .file-box {
        font-size: 1rem;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 15px 0;
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 576px) {
    .tva-info-col {
      .form-line {
        display: flex;
        justify-content: start !important;
        label{
          text-align: start;
        }
      }
    }
  }

  @media (min-width: 767px) and (max-width: 991px) {
    label.form-label {
      max-width: 156px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media (min-width: 992px) and (max-width: 1080px) {
    label.form-label {
      max-width: 190px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.bottom-actions {
  .btn {
    margin: 5px;
  }
}
