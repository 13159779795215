@use "../../scss/abstracts/" as *;

.main-User-client {
  background: url(../../assets/images/bg-digi.png) no-repeat center;
  background-size: cover;
  min-height: 100vh;
  .header {
    background: $white;
    padding: 15px 0;
    margin-bottom: 70px;
    @media (max-width:576px){
      .topnav { 
        padding: 0; 
    }
    }
  }
  .inner-header {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    .left-header {
      display: flex;
    }
    .retour-btn {
      min-height: 50px !important;
      height: 50px;
      img {
        max-width: 35px;
        height: auto;
      }
      @media (max-width: 576px) {
        padding: 5px 8px;
        height: 40px;
        img {
          max-width: 22px;
          height: auto;
        }
        span {
          display: none;
        }
      }
    }
  }
  .logo-client {
    display: flex;
    position: relative;
    margin-left: 20px;
    padding-left: 170px;
    align-items: flex-end;
    font-size: 1.5rem;
    font-family: $tt-demibold;
    color: $black;
    text-transform: uppercase;
    .img-logo {
      background-color: $white;
      color: $secondaryColor;
      box-shadow: inset 0px 4px 5px 0px lighten($black, 55);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 140px;
      height: 140px;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      img {
        max-width: 145px;
        width: 100%;
        height: auto;
        border-radius: 20%;
      }
    }
    @media (max-width: 640px) {
      padding-left: 85px;
      align-items: center;
      font-size: 1rem;
      margin-left: 15px;
      .img-logo {
        border-radius: 20px;
        width: 70px;
        height: 70px;
        img {
          max-width: 60px;
          border-radius: 20px;
        }
      }
    }
    @media (max-width: 479px) {
      padding-left:70px;
      margin-left: 13px;
      .img-logo { 
        width: 55px;
        height: 55px;
        img {
          max-width: 50px; 
        }
      }
    }
  }
  .container-full {
    max-width: 1730px;
    margin: 0 auto;
    width: 100%;
  }
  .client-wrrap {
    > .tabs-component {
      > .tablist {
        display: flex;
        background: transparent;
        box-shadow: none;
        .btn {
          flex: 1 0;
          padding: 0.5rem;
          font-size: 1.1rem;
          background: $white;
          box-shadow: -2px 4px 15px lighten($secondaryColor, 30);
          margin: 0 10px;
          position: relative;
          border: 1px solid transparent;

          &.active {
            background-color: $white;
            color: $secondaryColor;
            box-shadow: inset 0px 4px 5px 0px lighten($secondaryColor, 15);
            border-color: lighten($secondaryColor, 15);
            &::before {
              content: "";
              position: absolute;
              height: 8px;
              width: 8px;
              bottom: -13px;
              left: 50%;
              transform: translateX(-50%);
              background-color: $secondaryColor;
              border-radius: 50%;
              z-index: 1;
            }
            &::after {
              content: "";
              position: absolute;
              height: 40px;
              width: 40px;
              bottom: -20px;
              left: 50%;
              transform: translateX(-50%) rotate(45deg);
              background-color: $white;
              border-radius: 15px 0 15px 0;
              z-index: 0;
              box-shadow: 1px 1px 0px lighten($secondaryColor, 15);
            }
          }
          .icon-tabs {
            min-height: 75px;
            padding: 15px 0 10px;
            img {
              max-height: 75px;
              width: auto;
            }
          }
          .label-span {
            display: block;
            color: $black;
            line-height: 18px;
            font-size: 1.02rem;
            font-family: $tt-demibold;
          }
          .icon-tabs {
            display: block;
            img {
              max-width: 75px !important;
              margin: 0 0 5px !important;
            }
          }
        }
        @include tablet {
          overflow-x: auto;
          padding-bottom: 25px;
          flex-wrap: nowrap;
          flex-direction: row;
          .btn {
            min-width: 150px;
          }
        }
      }
    }
  }

  @media (max-width: 991px){
    .topnav { 
      padding: 15px;
      margin: 0 ;
      box-shadow: none;
  }
  }
}
