@use "../../../scss/abstracts/" as *;
.wrrap-notes {
  padding: 15px 0;
}
.section-notes {
  padding: 25px 0 15px;
  .Boxe-date-notes {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }
  .date-note {
    width: 115px;
    margin-right: 10px;
    .note-time {
      background: url(../../../assets/images/icons/calendar-icon-bg-2.png)
        no-repeat center;
      width: 115px;
      height: 105px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $black;
      justify-content: center;
      padding: 17px 5px 0;
      .day-note {
        display: block;
        font-size: 25px;
        line-height: 25px;
        font-family: $tt-extrabold;
      }
      .month-note,.year-note {
        display: block;
        font-size: 12px;
        line-height: 12px;
        font-family: $tt-demibold;
      }
    }
    @media (max-width: 576px) {
      margin: 5px auto;
    }
  }
  .note-content {
    width: calc(100% - 135px);

    &.note-form {
      padding: 15px 0 15px 135px;
      .form-control {
        background: $white;
        &.text-area-custom {
          height: 100px !important;
        }
        &.form-primary {
          border: 2px dashed $primaryColor;
        }
        &.form-secondary {
          border: 2px dashed $secondaryColor;
        }
        &.form-warning {
          border: 2px dashed $orange;
        }
        &.form-danger {
          border: 2px dashed $red;
        }
      }
      .btn {
        min-width: 180px;
      }
      @media (max-width: 576px) {
        padding: 15px 0;
      }
    }
    @media (max-width: 576px) {
      width: 100%;
      margin-top: 15px;
    }
  }

  .time-top {
    position: relative;
    display: block;
    span {
      display: inline-block;
      background-color: $white;
      padding: 5px;
      position: relative;
      z-index: 9;
      color: $secondaryColor
    }
    &:after {
      content: "";
      height: 1px;
      background: rgba(114, 114, 114, 0.2);
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      z-index: 0;
    }
  }
  .item-note-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 20px 40px;
    p {
      width: calc(100% - 150px);
    }
    .btn {
      display: flex;
      align-items: center;
      color: $red;
      img,
      svg {
        position: relative;
        top: 7px;
      }
    }
    @media (max-width: 767px) {
      align-items: flex-start;
      flex-direction: column;
    }
  }
}
.bottom-actions {
  .btn {
    margin: 5px;
  }
}
