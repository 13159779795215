@use "../../scss/abstracts/" as *;

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 80px;
  height: 38px;
  background: transparent;
  border: 1px solid $orange;
  box-shadow: inset 0px 4px 5px 0px lighten($black, 85);
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  @media (max-width: 576px) {
    width: 60px;
    height: 30px;
  }
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  transition: 0.2s;
  background: $orange;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  @media (max-width: 576px) {
    width: 25px;
    height: 25px;
  }
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}
