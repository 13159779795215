@use "../../scss/abstracts/" as *;
.therm-wrapper {
  .price-solde {
    color: $secondaryColor;
    font-size: 2.5rem;
    line-height: 55px;
    font-family: $tt-bold;
    text-align: center;
    @media (max-width: 1350px) {
      font-size: 2.1rem;
      line-height: 45px;
    }
    @media (max-width: 576px) {
      font-size: 2rem;
      line-height: 45px;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  .therm-render {
    max-height: 100%;
    .col {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 100%;
        width: auto;
      }
    }
    .therm-render-image {
      position: relative;
      .therm-value {
        position: absolute;
        bottom: 18%;
        left: 46%;
        color: $white;
        font-weight: 700;
      }
    }
  }

  .therm-text {
    margin-top: 2rem;
  }
}
