@use "../../../scss/abstracts/"as *;

.section-import {
  .card {
    padding: 50px 15px;
  }
}

.card-body {
  h3 {
    color: #b0b0b0;
    font-family: $tt-demibold;
    margin-bottom: 35px;
  }

  .file-uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 0;

    h3 {
      border-bottom: 2px dashed $default;
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      margin: 15px 10px;
    }
  }

  .action-states {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .list-Files{
      margin-bottom: 10px;
    }
  }

  .status-messages {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

  }

  .import-transactions {
    width: 30%;
    justify-content: space-between;
  }
}

.invoice-viewer {
  padding: 10px;
  border: 2px dashed $secondaryColor;
  border-radius: 0.5rem;

  .rpv-toolbar__right {
    display: none;
  }

  .rpv-core__viewer.rpv-core__viewer--light{
    max-height: 450px;
    height: 100%;
  }

  #react-doc-viewer {
    border-radius: 0.5rem;
    // &:hover {
    //   transform: scale(1.2);
    // }
    #header-bar {
      display: none;
    }
    #image-renderer {
      max-width: 450px;
    }
  }
}
