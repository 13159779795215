@use "../../../scss/abstracts/" as *;

.card-Table {
  position: relative;
  margin: 0 0 50px;

  .bottom-pagination {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -90px;
  }
  @media (max-width: 557px) {
    margin: 0 0 40px;
  }
}

.file-table {
  .form-file {
    padding: 0;
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 5px !important;

    .file-box {
      border-radius: 20px;
      padding: 5px 10px;
      width: 100%;

      span {
        font-size: 0.85rem;
      }
    }
  }

  .file-box-item {
    color: #000 !important;
    background: transparent;
    font-size: 0.85rem;
    font-family: $tt-medium;
    padding: 5px 10px 0;
    box-shadow: none;
    height: auto;
  }

  .list-Files {
    margin-top: 0 !important;
  }
}

.top-chois-form {
  padding: 15px 0;
}

.left-label {
  text-align: center;
  color: $primaryColor;
}

.list-encaissements-choix {
  padding: 15px 0;

  .choix-item {
    font-size: 1.2rem;
    color: $black;
    border-radius: 50px;
    padding: 10px 15px;
    min-height: 60px;
    width: 100%;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    transition: all 0.3s;
    position: relative;
    left: 0;
    line-height: 20px;
    text-align: center;
    flex-direction: column;

    &:hover,
    &:focus,
    &.selected {
      border-color: $primaryColor;
      color: $primaryColor;
      transition: all 0.3s;
    }

    &:hover {
      left: -10px;
    }
    p {
      margin-bottom: 0;
    }
  }
}

.decaissements-block {
  position: relative;
}

.remunerations-block {
  background: lighten($primaryColor, 35);
  border-radius: 30px;
  overflow: hidden;
  margin: 15px 15px 0 0;
  @media (max-width: 767px) {
    margin: 15px 0 0;
  }
  ul {
    padding: 3px 5px;
    margin-bottom: 0;

    li {
      padding: 0 0 1px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 1px;
        background: lighten($primaryColor, 20);
        left: 25px;
        right: 25px;
        bottom: 0;
        z-index: 0;
        opacity: 1;
        transition: all 0.2s ease-in-out;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      .name-cat-span {
        text-align: center;
        font-family: $tt-medium;
        padding: 12px 15px;
        color: $primaryColor;
        border-radius: 30px;
        font-size: 1.2rem;
        display: block;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        position: relative;
        z-index: 1;
      }
      &.active,
      &:hover {
        .name-cat-span {
          background: lighten($primaryColor, 5);
          box-shadow: inset 0px 4px 6px 0px darken($primaryColor, 15);
          border-color: $primaryColor;
          font-size: 1.2rem;
          color: $white;
          transition: all 0.2s ease-in-out;
        }
        &:after {
          opacity: 0;
          transition: all 0.2s ease-in-out;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.red-num {
  color: $red;
  font-size: 1.13rem;
  font-family: $tt-medium;
}

.green-num {
  color: $green;
  font-size: 1.3rem;
  font-family: $tt-medium;
}

.category-selection-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .category-selection-button {
    &.btn-outline-primary {
      padding: 2px 15px;
      min-height: 50px;
    }
  }
}

.file-table {
  width: 100%;

  .transaction-file {
    cursor: pointer;
  }
}

.asset-type-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  svg {
    color: $secondaryColor;
  }

  span {
    font-weight: 400;
    line-height: 1.5;
    color: $secondaryColor;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    padding: 0.75rem;
    font-size: 1rem;
  }
}

.custom_search-top {
  border: 1px dashed $secondaryColor;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 20px;
}

.custom-card {
  .rdt_TableHeadRow {
    .rdt_TableCol {
      &:last-child {
        min-width: 130px;
        justify-content: flex-start;
      }
    }
  }

  .rdt_TableRow {
    .rdt_TableCell {
      &:last-child {
        justify-content: flex-start;
      }
    }
  }
}
