@use "../scss/abstracts/" as *;

.main {
  padding-left: $sideBarWidth;
  @media (max-width: 1500px) {
    padding-left: 120px;
  }
  @media (max-width: 991px) {
    padding-left: 0;
    width: 100%;
    overflow-x: hidden;
  }

  &__content {
    min-height: 100vh;
    padding: $spacing 50px;
    transition: all 0.5s ease-in-out;
    // background: url(../assets/images/bg-user.png) no-repeat right top;
    // background-size: cover;
    @media (max-width: 1600px) {
      padding: $spacing;
    }

    @include tablet {
      padding: $mobileSpacing 15px;
      position: relative;
      background-color: $bodyBg;
      z-index: 100;
    }
    @media (max-width: 991px) {
      padding: 0 15px 50px;
    }
  }
}

.sidebar-open .main__content {
  height: 100vh;
  overflow: hidden;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  transform: scale(0.8) translateX(50%);
  transition: all 0.5s ease-in-out;
}
.top-content {
  margin-bottom: 20px;
  &.client-resize-header {
    .Banner {
      min-height: 150px;
      h2 {
        margin-bottom: 0;
        @media (max-width: 400px) {
          max-width: 80%;
        }
      }
      .Banner-img {
        bottom: 0px;
        top: auto;
        img {
          max-height: 200px;
        }
        @media (min-width: 400px) and (max-width: 991px) {
          bottom: auto;
          img {
            max-height: 130px;
            max-width: 130px;
          }
        }
        @media (max-width: 400px) {
          bottom: auto;
          img {
            max-height: 100px;
            max-width: 100px;
          }
        }
      }
    }
    .blockUser {
      height: 150px;
      justify-content: space-evenly;
      .user-info {
        width: 120px;
        .bgCercle {
          width: 120px;
          height: 120px;
        }
        .user-info__img {
          --size: 105px;
          max-width: var(--size);
          height: 105px;
          width: 100%;
          img {
            width: 105px;
            height: 105px;
          }
        }
      }
    }
  }
}

.card-filter {
  padding: 20px;
}
.blockUser {
  height: 100%;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  @media (max-width: 991px) {
    height: auto !important;
    order: 0;
    padding: 10px 0;
    background: transparent;
    box-shadow: none;
    justify-content: center !important;
    position: absolute;
    top: -112px;
    right: 60px;
    .btn {
      margin: 0 5px;
    }
  }
  @media (max-width: 420px) {
    top: -100px;
    right: 50px;
    .btn.btn-icon {
      border-radius: 10px;
      font-size: 1.4rem;
      height: 40px;
      min-height: 40px;
      min-width: 40px;
      img,
      svg {
        max-width: 22px;
      }
    }
  }
}
.blockBanner {
  @media (max-width: 991px) {
    order: 1;
  }
}

.topnav__right-item {
  @media (max-width: 420px) {
    .btn.btn-icon {
      border-radius: 10px;
      font-size: 1.4rem;
      height: 40px;
      min-height: 40px;
      min-width: 40px;
    }
  }
}

.card-Table {
  div.cvrUEL {
    overflow-y: visible;
  }
}
