@use "../../scss/abstracts/" as *;

.actionsFilter {
  display: flex;
  .btn {
    margin: 0 5px;
    flex: 1 0;
  }
}

.rdt_TableCell {
  cursor: pointer;
}

.filterInner {
  @media (max-width: 750px) {
    .row {
      .col {
        flex: initial;
      }
    }
  }
}
