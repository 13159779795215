@use "../../scss/abstracts/" as *;
@use "sass:math";

.client-info-tab {
  &.btn {
    max-height: 100px;
    justify-content: space-between !important;
    @media (max-width: 767px) {
      justify-content: center !important;
    }
    .label-span {
      padding-left: 0.8rem;
    }
    .icon-tabs {
      align-self: flex-end;
      padding: 15px 0 0 !important;
    }
  }
  &.parent-wrapper {
    .label-span {
      font-size: 1.8rem !important;
      line-height: 1.8rem !important;
      @media (max-width: 576px) {
        font-size: 1.3rem !important;
        line-height: 1.4rem !important;
      }
    }
  }
  &.child-wrapper {
    .label-span {
      font-size: 1.1rem !important;
    }
  }
}

.whitelabel-edit-tab {
  min-width: 380px !important;
  padding: 1.8rem 1.6rem 1.6rem 0.2rem !important;
}

.tabs-component {
  .tablist {
    display: inline-flex;
    flex-wrap: wrap;
    position: relative;
    color: $secondaryColor;
    background: lighten($secondaryColor, 35);
    box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
    border-color: $secondaryColor;
    border-radius: 35px;
    padding: 2px;
    z-index: 9;
    @media (max-width: 576px) {
      flex-direction: column;
      width: 100%;
    }
    &.tabs-primary {
      color: $primaryColor;
      background: lighten($primaryColor, 35);
      box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
      border-color: $primaryColor;
    }

    .btn {
      display: block;
      padding: 0.5rem 2.8rem;
      min-height: 50px;
      line-height: 35px;
      font-size: 0.8rem;
      border: none;
      margin: 0 2px;
      outline: none;
      color: $secondaryColor;
      background-color: transparent;
      transition: border 0.2s ease-in-out 0s;
      &.active,
      &:focus,
      &:hover {
        border-width: 1px;
      }
      &.active {
        background-color: $white;
        color: $secondaryColor;
        box-shadow: inset 0px 4px 5px 0px lighten($secondaryColor, 25);
      }
      span {
        position: relative;
        z-index: 9;
      }
      @media (max-width: 1550px) {
        padding: 0.5rem 1.8rem;
      }
      @media (max-width: 576px) {
        padding: 0.5rem 1.5rem;
      }
    }
  }
  .tabpanel {
    text-align: left;
    padding: 1rem;
    @media (max-width: 576px) {
      padding: 1rem 0;
    }
  }
  &.vertical {
    display: flex;
    flex-wrap: wrap;
    .tablist {
      flex-direction: column;
    }
    .tabpanel {
      flex: 1;
    }
  }
}
.full-tabs {
  .tabs-component .tablist {
    background: transparent;
    display: flex;
    box-shadow: none;
    padding: 0;
    .btn {
      flex: 1 0;
      background-color: $white;
      color: $secondaryColor;
      box-shadow: inset 0px 4px 5px 0px lighten($secondaryColor, 25);
      position: relative;
      padding: 0.85rem 2.8rem;
      border: 1px solid $secondaryColor;
      &.active {
        flex: 1 0;
        background-color: $secondaryColor;
        color: $white;
        box-shadow: inset 0px 4px 5px 0px darken($secondaryColor, 12);
        &::before {
          content: "";
          position: absolute;
          height: 6px;
          width: 6px;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
          background-color: $white;
          border-radius: 50%;
          z-index: 1;
        }
        &::after {
          content: "";
          position: absolute;
          height: 60px;
          width: 60px;
          bottom: -15px;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          background-color: $secondaryColor;
          border-radius: 15px;
          z-index: 0;
        }
      }
      @media (max-width: 576px) {
        padding: 0.85rem 1.5rem;
        margin-bottom: 20px;
        width: 100%;
        flex: initial;
      }
    }
  }
  .tabpanel {
    padding: 10px 0;
  }
}

.whitelabel-edit-button {
  position: absolute;
  background: #fff;
  font-size: 1.3rem;
  color: $secondaryColor;
  height: 45px;
  width: 45px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45px;
  box-shadow: -2px 4px 10px #90cde9;
  border: none;
  border-radius: 50%;
  left: 90%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
