@use "../../../scss/abstracts/" as *;
.white-label-section {
  padding: 15px;
  
  .tabs-component.vertical {
    .tablist {
    
      .btn {
        background-color: #f5f5f5;
        color: #50b1dc;
        box-shadow: inset 4px 4px 5px 0px #cccc;
        position: relative;
        padding: 1.6rem 1.8rem;
        border: 2px dashed transparent;
        border-radius: 15px 0 0 15px;
        position: relative;
        left: -3px;
        margin-left: 20px;
        transition: all 0.3s;
        min-width: 260px;
        max-height: 115px;
        &.active {
          border: 2px dashed $secondaryColor;
          border-right: none;
          background: $white;
          box-shadow: inset 4px 0px 5px 0px #bae0f1;
          left: -1px;
          margin-left: 0;
          transition: all 0.3s;
  
          &:after {
            background: #243859;
            width: 4.5px;
            height: 50px;
            right: 18px;
            top: 50%;
            left: initial;
            transform: translateY(-50%);
          }
          &:before {
            border-top: 14px solid transparent;
            border-bottom: 14px solid transparent;
            border-left: 16px solid $secondaryColor;
            content: "";
            right: 2px;
            top: 50%;
            left: initial;
            transform: translateY(-50%);
            position: absolute;
            border-radius: 0 !important;
            transition: all 0.3s;
          }
        }
        img {
          max-width: 200px;
          max-height: 60px;
          height: auto;
          width: auto;
        }
      }

      @media (max-width: 1150px) {
        width: 100%;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-x: auto;
        padding-bottom: 10px;
        .btn { 
          border-radius: 15px ; 
          left: 0; 
          margin: 5px;
          min-width: 250px !important;
          padding-right: 68px !important;
          &.active {
            border: 2px dashed $secondaryColor;
            border-right: 2px  dashed $secondaryColor;
            background: $white;
            box-shadow: inset 4px 0px 5px 0px #bae0f1;
            left: 0;
            margin-left: 0;
            transition: all 0.3s;
            &:before {
              top: 100%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(90deg);
          }
            &:after {
              top: 88%;
              left: 50%;
              transform: translate(-50%,-50%) rotate(90deg);
          }
         
        
          }
          img {
            max-width: 100px;
            max-height: 50px; 
          }
        }
      }
    }
    @media (max-width: 991px) {
      display: block;
    }
  }
  .tabpanel {
    border-radius: 0 15px 15px 0;
    border: 2px dashed $secondaryColor;
    position: relative;
    left: -5px;
    padding-bottom: 25px;
    @media (max-width:991px) {
      border-radius: 0 0 15px 15px;
      left: 0;
    }
  }
  @media (max-width:576px) {
   
    .card-Table {
      margin: 0 0 55px;
    }
  }
}
