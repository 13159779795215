@use "../../scss/abstracts/" as *;
@use "sass:math";

.bottom-pagination {
  padding: 30px 0 15px;
  @media (max-width: 1199px) { 
    display: flex;
  }
}
.page-item:first-child .page-link {
  border-radius: 50%;
  box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
  background: $white !important;
  margin-right: 11px;
}
.page-item:last-child .page-link {
  border-radius: 50%;
  min-height: 35px;
  box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
  background: $white !important;
  margin-left: 11px;
}
.pagination {
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  &.pagination-primary {
    .page-link {
      border: none;
      color: $primaryColor;
      background: lighten($primaryColor, 35);
    }
    .page-item {
      &.active,
      &:hover,
      &:focus {
        .page-link {
          background: $primaryColor !important;
          color: $white;
          outline: 0;
          box-shadow: none;
        }
      }
    }
  }
  &.pagination-secondary {
    .page-link {
      border: none;
      color: $secondaryColor;
      background: lighten($secondaryColor, 35);
    }
    .page-item {
      &.active,
      &:hover,
      &:focus {
        .page-link {
          background: $secondaryColor !important;
          color: $white;
          outline: 0;
          box-shadow: none;
        }
      }
    }
  }
  .page-item {
    padding: 2px;
  }
  .page-link {
    border: none;
    color: $white;
    border-radius: 50%;
    min-width: 35px;
    height: 35px;
    padding: 0 5px;
    text-align: center;
    line-height: 35px;
  }


  @media (max-width: 1199px) { 
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 10px;
    margin:  0 auto;
}



}
