@use "../../../scss/abstracts/" as *;
@use "sass:math";

.vehicule-banner {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 40px 15px 15px 30px;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-radius: 25px;
  position: relative;
  min-height: 275px;
  box-shadow: 0 5px 20px lighten($secondaryColor, 15);
  .btn {
    position: relative;
    z-index: 99;
  }
  @media (max-width: 1350px) {
    padding: 15px; 
    border-radius: 30px; 
    min-height: 215px;
  }
  @media (max-width: 557px) {  
    min-height: 200px;
  }
  .vehicule-banner__text {
    width: 60%;
    @media (max-width: 1350px) {
      width: 80%;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .vehicule-banner__title {
    font-size: 1.8rem;
    font-family: $tt-demibold;
    color: $black;
    margin-bottom: 25px;
  }

  .vehicule-banner__img {
    // background: url(../../assets/images/bg-banner-filter.svg) no-repeat bottom;
    background-size: 90%;
    max-width: 450px;
    position: absolute;
    right: 15px;
    bottom: 0;
    padding: 0 20px 0 0;

    @media   (max-width: 1600px) {
      max-width: 320px;
      right: 0px;
    }
    @media  (max-width: 1350px) {
      max-width: 200px; 
    }
    @media (min-width: 1600px) and (max-width: 1744px) {
      padding-right: 0px;
    }
    img {
      max-width: 450px;
      height: auto;

      @media  (min-width: 1745px) and (max-width: 1840px){
        max-width: 220px;
      }
      @media (min-width: 1351px) and (max-width: 1744px) {
        max-width: 185px; 
      }
      @media  (max-width: 1350px) {
        max-width: 160px; 
      }
      @media  (max-width: 1199px) {
        max-width: 200px; 
      }
    }
    @media (max-width: 1199px) {
      padding: 0 5px 0 0;
    }
    @media (max-width: 576px) {
      max-width: 150px;
      right: 0;
      bottom: 25px;
      padding: 0px 10px 0 0;
      img {
        max-width: 125px;
      }
    }
  }

  p {
    margin: 0px 50px 0 0;
    font-size: 1rem;
    line-height: 20px;
    color: white;
  }
}

.Link {
  text-decoration: underline;
  color: white;
  text-underline-offset: 1px;
}

@media (max-width: 950px) {
  .vehicule-banner__ {
    flex-direction: column;
  }

  .More {
    margin-top: 8px;
    margin-bottom: 4px;
    margin-left: 0px;
  }
}

@media (max-width: 720px) {
}
