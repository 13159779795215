@use "../../scss/abstracts/" as *;

.spinner-card {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner-logo {
    width: 20%;
  }
  .spinner-msg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin-top: 10px;
      margin-right: 8px;
      font-size: 1.5rem;
      font-weight: bold;
    }
    .spinner-msg_main {
      p {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
    .spinner-msg_secondary {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  
    }
  }
}
