@use "./variables" as *;

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  line-height: 25px;
  display: inline-block;
  color: $txtColor;
  font-size: 1.1rem;
  font-family: $tt-medium;
  @media (max-width: 576px) {
    padding-left: 25px;
  }
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 1px solid #ddd;
  border-radius: 50%;
  background: #fff;
  box-shadow: inset 0px 4px 5px 0px lighten($black, 85);
  @media (max-width: 576px) {
    width: 20px;
    height: 20px;
  }
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 17px;
  height: 17px;
  background: $primaryColor;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  @media (max-width: 576px) {
    width: 12px;
    height: 12px;
  }
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.check-primary {
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border: 1px solid $primaryColor;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: $primaryColor;
  }
}
.check-secondary {
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border: 1px solid $secondaryColor;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: $secondaryColor;
  }
}
.check-warning {
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border: 1px solid $orange;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: $orange;
  }
}
.check-danger {
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border: 1px solid $red;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: $red;
  }
}
