@use "../../../scss/abstracts/" as *;
.models-view-section {
  padding: 15px;
  @media (max-width: 767px) {
    padding: 15px 0;
  }
  .models-view-component {
    display: flex;
    .models-list {
      width: 30%;
      flex-direction: column;
      background: transparent;
      box-shadow: none;
      display: flex;
      padding: 0;
      // max-height: 1100px;
      overflow-y: scroll;
      border-radius: 15px;
      border: 2px dashed $primaryColor;
      padding: 5px;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px lighten($mainBg, 30);
        box-shadow: inset 0 0 6px lighten($mainBg, 30);
        border-radius: 10px;
        background-color: $mainBg;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: transparent;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px lighten($mainBg, 30);
        box-shadow: inset 0 0 6px lighten($mainBg, 30);
        background-color: darken($mainBg, 30);
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .model-btn {
        // background-color: #f5f5f5;
        background-color: transparent;
        position: relative;
        padding: 1.3rem 1.5rem 1.3rem 0;
        border: 2px dashed transparent;
        border-radius: 15px 0 0 15px;
        position: relative;
        left: -3px;
        margin-left: 20px;
        transition: all 0.3s;
        min-width: 260px;
        max-height: 115px;

        .model-tab {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .model-tab-label {
            font-size: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            svg {
              fill: $default;
              height: 35px;
              width: auto;
            }
            .model-tab-label-text {
              margin-left: 3px;
              text-align: start;
              max-width: 210px;
              width: 200px;
            }
          }
        }
        &.active {
          border-right: none;
          background: $white;
          transition: all 0.3s;

          &:after {
            background: #243859;
            width: 4.5px;
            height: 50px;
            right: 18px;
            top: 50%;
            left: initial;
            transform: translateY(-50%);
          }
          &:before {
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-left: 14px solid $primaryColor;
            content: "";
            right: 0px;
            top: 50%;
            left: initial;
            transform: translateY(-50%);
            position: absolute;
            border-radius: 0 !important;
            transition: all 0.3s;
          }
          .model-tab {
            .model-tab-label {
              svg {
                fill: $primaryColor;
              }
            }
          }
        }
      }
    }
    .modelPanel {
      width: 70%;
      margin-left: 5px;
      border-radius: 15px;
      border: 2px dashed $primaryColor;
      padding: 25px;
    }
    @media (max-width: 1199px) {
      flex-direction: column;
      .models-list {
        width: 100%;
        margin: 0 0 15px;
        display: flex;
        flex-direction: row;
        border: none;
        padding: 5px 0; 
        overflow-y: hidden;
        .model-btn { 
          border-radius: 15px;
          padding: 10px 25px 10px 10px;
          margin-left: 12px;
          border-width: 1px;
          border-color: rgba(0, 0, 0, 0.2);
          border-style: solid;
          left: 0; 
          min-width: 250px;
          .model-tab-label-text {
            max-width: 130px !important;
            width: 130px !important;
            line-height: 20px; 
          }
          &.active {
            border-color: $primaryColor;
            border-right:  1px solid $primaryColor;
            &:before { 
              right: 50%;
              top: 100%; 
              transform: translateY(-50%) rotate(90deg); 
          }
          }
        }
      }
      .modelPanel {
        width: 100%;
        margin-left: 0;
        padding: 15px;
        border: none;
      }
    }
    @media (max-width: 767px) {
      .modelPanel {
        padding: 15px 0;
      }
    }
  }
}
