@use "./variables" as *;
.text-area-custom {
  height: 195px !important;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: $primaryColor;
    border: 0px none transparent;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border: 1px none transparent;
    border-radius: 5px;
  }
}
.form-control {
  border: none;
  height: 60px;
  border-radius: 35px;
  font-size: 1.15rem;
  font-family: $tt-medium;
  padding-left: 1.3rem;
  @media (max-width: 1199px) {
    height: 45px;
    padding-left: 1.1rem;
    font-size: 1.05rem;
    line-height: 18px;
  }
  &.small {
    height: 45px;
  }
  &.outline {
    background: $white !important;
    border: 1px solid transparent;
  }
  &.form-primary {
    color: $primaryColor;
    background: lighten($primaryColor, 35);
    box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
    border-color: $primaryColor;
    &::-webkit-input-placeholder {
      color: lighten($primaryColor, 10);
    }

    &:-ms-input-placeholder {
      color: lighten($primaryColor, 10);
    }

    &::placeholder {
      color: lighten($primaryColor, 10);
    }
    a {
      color: $primaryColor;
    }
    &::-webkit-scrollbar-thumb {
      background: $primaryColor;
    }
    &.outline {
      border-color: $primaryColor !important;
    }
  }
  &.form-secondary {
    color: $secondaryColor;
    background: lighten($secondaryColor, 35);
    box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
    border-color: $secondaryColor;
    &::-webkit-input-placeholder {
      color: lighten($secondaryColor, 10);
    }

    &:-ms-input-placeholder {
      color: lighten($secondaryColor, 10);
    }

    &::placeholder {
      color: lighten($secondaryColor, 10);
    }
    a {
      color: $secondaryColor;
    }
    &::-webkit-scrollbar-thumb {
      background: $secondaryColor;
    }
    &.outline {
      border-color: $secondaryColor !important;
    }
  }
  &.form-warning {
    color: $orange;
    background: lighten($orange, 25);
    box-shadow: inset 0px 4px 6px 0px lighten($orange, 15);
    border-color: $orange;
    &::-webkit-input-placeholder {
      color: lighten($orange, 10);
    }

    &:-ms-input-placeholder {
      color: lighten($orange, 10);
    }

    &::placeholder {
      color: lighten($orange, 10);
    }
    a {
      color: $orange;
    }
    &::-webkit-scrollbar-thumb {
      background: $orange;
    }
    &.outline {
      border-color: $orange !important;
    }
  }
  &.form-danger {
    color: $red;
    background: lighten($red, 35);
    box-shadow: inset 0px 4px 6px 0px lighten($red, 25);
    border-color: $red;
    &::-webkit-input-placeholder {
      color: lighten($red, 10);
    }

    &:-ms-input-placeholder {
      color: lighten($red, 10);
    }

    &::placeholder {
      color: lighten($red, 10);
    }
    a {
      color: $red;
    }
    &::-webkit-scrollbar-thumb {
      background: $red;
    }
    &.outline {
      border-color: $red !important;
    }
  }
  &.form-default {
    color: $black;
    background: lighten($default, 20);
    box-shadow: inset 0px 4px 6px 0px lighten($default, 20);
    border-color: $default;
    &::-webkit-input-placeholder {
      color: lighten($default, 2);
    }

    &:-ms-input-placeholder {
      color: lighten($default, 2);
    }

    &::placeholder {
      color: lighten($default, 2);
    }
    &.outline {
      border-color: #ccc !important;
    }
  }
}
.form-label {
  font-family: $tt-medium;
  font-size: 1.1rem;
  color: $black;
  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 20px;
  }
  &.label-primary {
    color: $primaryColor;
  }
  &.label-secondary {
    color: $secondaryColor;
  }
  &.label-warning {
    color: $orange;
  }
  &.label-danger {
    color: $red;
  }
}
.form-icon {
  position: relative;

  &.icon-start {
    .form-control {
      padding-left: 70px;
      @media (max-width: 1199px) {
        padding-left: 50px;
      }
    }
    .icon {
      left: 1px;
      bottom: 1px;
    }
  }
  &.icon-end {
    .form-control {
      padding-right: 70px;
      @media (max-width: 1199px) {
        padding-right: 50px;
      }
    }
    .icon {
      right: 1px;
      bottom: 1px;
    }
  }
  .icon {
    color: $secondaryColor;
    background: $white;
    height: 58px;
    width: 58px;
    border-radius: 50%;
    position: absolute;
    border: none;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: auto;

    img {
      max-width: 35px;
      top: 2px;
      position: relative;
    }
    svg {
      max-width: 35px;
    }
    @media (max-width: 1199px) {
      height: 43px;
      width: 43px;
      img {
        max-width: 28px;
        top: 3px;
      }
      svg {
        max-width: 28px;
      }
    }
    &.icon-primary {
      box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
      color: $primaryColor;
      svg {
        stroke: $primaryColor;
        defs {
          filter {
            feFlood {
              flood-color: $primaryColor;
            }
          }
        }
        g {
          path {
            stroke: $primaryColor;
          }
        }
      }
    }
    &.icon-primary-fill {
      svg {
        g {
          path {
            fill: $primaryColor;
          }
        }
      }
    }
    &.icon-secondary {
      box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
      color: $secondaryColor;
      svg {
        stroke: $secondaryColor;
        defs {
          filter {
            feFlood {
              flood-color: $secondaryColor;
            }
          }
        }
        g {
          path {
            stroke: $secondaryColor;
          }
        }
      }
    }
    &.icon-secondary-fill {
      svg {
        g {
          path {
            fill: $secondaryColor;
          }
        }
      }
    }
    &.icon-warning {
      box-shadow: inset 0px 4px 6px 0px lighten($orange, 25);
      color: $orange;
      svg {
        path {
          fill: $orange;
        }
      }
    }
    &.icon-danger {
      box-shadow: inset 0px 4px 6px 0px lighten($red, 25);
      color: $red;
    }
  }
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="month"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  z-index: 99;
}
.file-upload-show {
  border: none;
  height: 60px;
  border-radius: 35px;
  font-size: 1.15rem;
  font-family: $tt-medium;
  padding-left: 1.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  svg {
    position: relative;
    margin-left: -10px;
  }
  &.form-primary {
    color: $primaryColor;
    background: lighten($primaryColor, 35);
    box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
    border-color: $primaryColor;
  }
  &.form-secondary {
    color: $secondaryColor;
    background: lighten($secondaryColor, 35);
    box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
    border-color: $secondaryColor;
  }
  &.form-warning {
    color: $orange;
    background: lighten($orange, 25);
    box-shadow: inset 0px 4px 6px 0px lighten($orange, 15);
    border-color: $orange;
  }
  &.form-danger {
    color: $red;
    background: lighten($red, 35);
    box-shadow: inset 0px 4px 6px 0px lighten($red, 25);
    border-color: $red;
  }
}
