@use "../../scss/abstracts/" as *;

.card-devis {
  padding: 50px 20px;
  text-align: center;
  align-items: center;
  box-shadow: -20px 30px 99px rgba(114, 114, 114, 0.12);
  .btn {
    min-width: 220px;
  }
  h3 {
    color: #b0b0b0;
    border-bottom: 2px dashed #b0b0b0;
    font-size: 27px;
    padding-bottom: 12px;
    margin-bottom: 30px;
    width: 70%;
    font-family: $tt-demibold;
    @media (max-width: 991px) {
      width: 100%;
      font-size: 20px;
      line-height: 25px;
    }
  }
  .card-devis-img {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-width: 400px;
    width: 100%;
    height: 340px;
    background: url(../../assets/images/svg/trace-bg.svg) no-repeat center;
    background-size: 100%;
    margin: 25px 0;
    img {
      max-width: 270px;
      height: auto;
    }
  }
  p {
    color: #fff;
    line-height: 35px;
    font-size: 25px;
    font-family: $tt-demibold;
    margin: 0;
    color: $orange;
  }
  &.devis-accepted {
    h3 {
      color: $primaryColor;
      border-color: $primaryColor;
    }
  }
  &.devis-refused {
    h3 {
      color: $orange;
      border-color: $orange;
    }
  }
}
