@use "../../scss/abstracts/"as *;

.not-found-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .card-modal-box {
        min-width: 50vw;
        padding: 50px 20px;
        text-align: center;
        align-items: center;
        box-shadow: -20px 30px 99px rgba(114, 114, 114, 0.2);

        h3 {
            color: #b0b0b0;
            border-bottom: 2px dashed #b0b0b0;
            font-size: 27px;
            margin-bottom: 30px;
            padding-bottom: 12px;
            width: 70%;
            font-family: $tt-demibold;

            @media (max-width: 991px) {
                width: 100%;
                font-size: 20px;
                line-height: 25px;
            }
        }

        .block-img-center {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            max-width: 400px;
            width: 100%;
            height: 340px;
            background: url(../../assets/images/svg/trace-bg.svg) no-repeat center;
            background-size: 100%;
            margin: 25px 0;

            img {
                max-width: 270px;
                height: auto;
            }
        }

        p {
            color: #fff;
            line-height: 35px;
            font-size: 25px;
            font-family: $tt-demibold;
            margin: 0;
            color: $orange;

            @media (max-width: 991px) {
                font-size: 20px;
                line-height: 25px;
            }
        }
        button{
            margin-top: 2rem;
        }
    }
}