@use "../../../scss/abstracts/" as *;
.section-Compte-sync {
  padding: 25px 0 15px;

  .headerBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: "1vw";
    .headerText {
      color: "green";
    }
    p {
      margin-bottom: 0px;
    }

    .headerIcon {
      display: none;
    }

    @media (max-width: 800px) {
      .headerText {
        display: none;
      }
      .headerIcon {
        display: block;
        width: 25px;
        height: 25px;
      }
    }
  }

  h3 {
    color: #b0b0b0;
    border-bottom: 2px dashed #b0b0b0;
    font-size: 27px;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      font-size: 20px;
    }
  }
  .btn {
    position: relative;
    &.icon-left {
      padding-left: 75px;
    }

    .icon {
      color: #50b1dc;
      background: #fff;
      height: 58px;
      width: 58px;
      border-radius: 50%;
      position: absolute;
      border: none;
      font-size: 1.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 91;
      left: 1px;
      top: 1px;
      svg {
        position: relative;
        top: 7px;
        max-width: 38px;
      }
      &.icon-primary {
        box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
        color: $primaryColor;
      }
      &.icon-secondary {
        box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
        color: $secondaryColor;
      }
      &.icon-warning {
        box-shadow: inset 0px 4px 6px 0px lighten($orange, 25);
        color: $orange;
      }
      &.icon-danger {
        box-shadow: inset 0px 4px 6px 0px lighten($red, 25);
        color: $red;
      }
    }
    @media (max-width: 1199px) {
      &.icon-left {
        padding-left: 55px;
      }
      .icon {
        height: 41px;
        width: 41px;
      }
    }
  }
}
