.archive_all__errors {
  p {
    font-size: 1.2rem;
  }
}

.action-top-tabs {
  display: flex;
  padding: 10px 0;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 29px;
  width: 80%;

  .btn {
    margin: 5px;
    min-height: 55px;
  }
  @media (max-width: 1480px) {
    position: static;
    justify-content: space-between;
    width: 100%;
  }
  @media (max-width: 576px) {
    flex-direction: column;
  }

  &.transactions-action-top-tabs {
    @media (min-width: 1080px) {
      align-items: center;
    }
    @media (min-width: 577px) and (max-width: 1079px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .transaction-search-bar {
        margin: 5px;
        .icon {
          &.icon-secondary {
            bottom: 1px !important;
          }
        }
      }
    }
  }
}

.immoblisation-selector-wrapper {
  .Banner-img {
    img {
      height: 95%;
      width: auto;
    }
  }
}
