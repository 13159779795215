@use "../../../scss/abstracts/" as *;

.main-signin {
  max-width: 50%;
  margin: 0 auto;
  @media (max-width: 767px) {
    max-width: 100%;
  }
}
.top-Login {
  text-align: center;
  margin: 0 0 50px;
  p {
    font-size: 1.05rem;
    color: #b0b0b0;
  }
  @media (max-width: 767px) {
    margin: 0 0 20px;
    line-height: 20px;
  }
}
.login-top {
  margin: 0 ;
  @media (max-width: 576px) {
    margin: 0 0 10px;
  }
 
  img {
    // max-height: 74px;
    max-width: 25%;
    @media (max-width: 576px) {
      max-height: 54px;
    }
  } 
  &.register-top{
    img {
      max-width: 20%;
    } 
  }
 
}
.connexion-form {
  .bottom-action {
    padding: 25px 0;
    .btn {
      width: 100%;
      text-align: center;
      box-shadow: 0px 30px 30px lighten($secondaryColor, 30);
      font-family: $tt-demibold;
      font-size: 1.5rem;
      &:hover {
        box-shadow: 0px 10px 30px lighten($secondaryColor, 30);
      }
    }
    @media (max-width: 576px) {
      padding: 15px 0 5px;
    }
  }
  .bottom-link {
    padding: 45px 0 0;
    text-align: center;
    color: $primaryColor;
    a{ 
      position: relative;
      &::before{
        content: "|";
        margin: 0 5px;
        display: inline-block; 
      }
      &:first-child{
        &::before{ 
          display: none; 
        }
      }
      &:hover {
        color: $secondaryColor;
        text-decoration: none;
      }
    }
    .forgot-pwd {
      text-decoration: underline;
     
    }
    @media (max-width: 576px) {
      padding: 25px 0 0;
    }
  }

  .register-mobile{
    display: none;
    @media (max-width: 991px) {
      display: inline-block;
    }
  }
}
