@use "../../scss/abstracts/" as *;
@use "sass:math";

.Banner {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 15px 15px 15px 30px;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-radius: 40px;
  position: relative;
  min-height: 275px;
  align-items: center;
  box-shadow: 0 5px 20px lighten($primaryColor, 15);
  @media (max-width: 1450px) {
    min-height: 200px;
  }
  @media (max-width: 991px) {
    min-height: 150px;
    margin-bottom: 12px;
  }
  .Banner-text {
    width: 60%;
    position: relative;
    z-index: 99;
  }
  h2 {
    font-size: 2.5rem;
    font-family: $tt-demibold;
    color: $white !important;
    margin-bottom: 25px;
    line-height: 35px;
  }
  .Banner-img {
    // background: url(../../assets/images/bg-banner-filter.svg) no-repeat bottom;
    background-size: 90%;
    max-width: 500px;
    position: absolute;
    right: 15px;
    bottom: 30px;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0 5px 0 0;
    img {
      max-width: 500px;
      height: auto;
      max-height: 275px;
    }
    @media (max-width: 1500px) {
      max-width: 350px;
      bottom: 15px;
      img {
        max-width: 350px;
      }
    }
    @media (max-width: 1450px) {
      max-width: 250px;
      img {
        max-width: 200px;
      }
    }
    @media (max-width: 991px) {
      max-width: 150px;
    }
  }
  p {
    margin: 0px 50px 0 0;
    font-size: 1rem;
    line-height: 20px;
    color: white;
  }
}

.Link {
  text-decoration: underline;
  color: white;
  text-underline-offset: 1px;
}
@media (max-width: 1199px) {
  .Banner h2 {
    font-size: 2rem;
    line-height: 30px;
  }
}
@media (max-width: 991px) {
  .More {
    margin-top: 8px;
    margin-bottom: 4px;
    margin-left: 0px;
  }

  .Banner h2 {
    font-size: 1.65rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .Banner {
    margin-bottom: 20px;
    .Banner-text {
      width: 100%;
    }
    h2 {
      font-size: 1.8rem;
      line-height: 25px;
    }
  }
}
@media (max-width: 576px) {
  .Banner-text {
    max-width: 160px;
  }
  .first__card__header {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center !important;
      padding: 5px 15px;
      .vh-icon {
        img {
          width: 100%;
          height: auto;
          max-width: 100%;
          margin: 0 7px 0 0;
        }
      }
    }
  }
  .create-indem__button {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center !important;
      img {
        width: 100%;
        height: auto;
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 450px) {
  .Banner {
    margin-bottom: 20px;
    min-height: 130px !important;
    padding-left: 15px;
    .Banner-img img {
      max-width: 150px;
    }
    h2 {
      font-size: 1.5rem;
      line-height: 25px;
    }
  }
}
