.wrapper.circles-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 400px;
  @media (max-width: 1350px) {
    min-height: 250px;
  }
  .inner {
    position: absolute;

    img {
      width: 80%;
      height: auto;
    }

    .red-text {
      color: #f06151;
      border-radius: 5px;
      padding: 2px 5px;
      font-weight: 600;
      background-color: white;
      z-index: 99;
      @media (max-width: 576px) {
        max-width: 100px;
      }
      &._grey {
        color: grey;
      }
    }

    .blue-text {
      color: #3d9ec5;
      border-radius: 5px;
      padding: 2px 5px;
      font-weight: 600;
      background-color: white;
      z-index: 99;
      @media (max-width: 576px) {
        max-width: 100px;
      }
      &._grey {
        color: grey;
      }
    }

    .yellow-text {
      color: #fdb344;
      border-radius: 5px;
      padding: 2px 5px;
      font-weight: 600;
      background-color: white;
      z-index: 99;
      &._grey {
        color: grey;
      }
    }

    .wrapper-20 {
      .generic-buble {
        border: 2px solid;
      }
      .text-20 {
        position: absolute;
        z-index: 99;

        &.red-text {
          right: -25%;
          top: 25%;
          @media (max-width: 767px) {
            right: 4px;
            top: 5%;
            max-width: 100px;
            font-size: 14px;
            line-height: 15px;
          }
        }

        &.yellow-text {
          right: 35%;
          top: 45%;
          @media (max-width: 767px) {
            font-size: 14px;
            line-height: 15px;
          }
        }

        &.blue-text {
          right: -40%;
          top: 45%;
          @media (max-width: 767px) {
            right: 4px;
            top: 47%;
            max-width: 100px;
            font-size: 14px;
            line-height: 15px;
          }
        }
      }
    }

    .wrapper-40 {
      .generic-buble {
        border: 2px solid;
      }
      .text-40 {
        position: absolute;
        z-index: 99;

        &.red-text {
          right: -20%;
          top: 85%;
          @media (max-width: 576px) {
            right: 0%;
            top: 65%;
          }
        }

        &.yellow-text {
          right: 35%;
          top: 45%;
        }

        &.blue-text {
          right: 38%;
          top: 77%;
          @media (max-width: 576px) {
            top: 70%;
          }
        }
      }
    }

    .wrapper-60 {
      .generic-buble {
        border: 2px solid;
      }
      .text-60 {
        position: absolute;
        z-index: 99;

        &.red-text {
          left: -5%;
          top: 95%;
          @media (max-width: 576px) {
            left: 0%;
          }
        }

        &.yellow-text {
          right: 35%;
          top: 45%;
        }

        &.blue-text {
          left: -30%;
          top: 75%;
          @media (max-width: 576px) {
            left: 0%;
            top: 65%;
          }
        }
      }
    }

    .wrapper-80 {
      .generic-buble {
        border: 2px solid;
      }
      .text-80 {
        position: absolute;
        z-index: 99;

        &.red-text {
          left: -30%;
          top: 50%;
          @media (max-width: 576px) {
            left: 5px;
            top: 29%;
            font-size: 14px;
            line-height: 15px;
          }
        }

        &.yellow-text {
          right: 35%;
          top: 45%;
        }

        &.blue-text {
          left: -40%;
          top: 25%;
          @media (max-width: 576px) {
            left: 5px;
            top: 0%;
          }
        }
      }
    }

    .wrapper-100 {
      .generic-buble {
        border: 2px solid;
      }
      .text-100 {
        position: absolute;
        z-index: 99;

        &.red-text {
          right: -10%;
          top: 5%;
          @media (max-width: 576px) {
            right: 5px;
            top: 0%;
          }
        }

        &.yellow-text {
          right: 35%;
          top: 45%;
        }

        &.blue-text {
          left: -30%;
          top: 15%;
          @media (max-width: 576px) {
            left: 5px;
            top: 0%;
          }
        }
      }
    }

    .wrapper-0 {
      .generic-buble {
        border: 2px solid;
      }

      .text-0 {
        position: absolute;
        z-index: 99;

        &.red-text {
          right: -2%;
          top: 5%;
        }

        &.yellow-text {
          right: 35%;
          top: 45%;
          border-color: grey;
        }

        &.blue-text {
          left: -20%;
          top: 15%;
          @media (max-width: 576px) {
            left: -5%;
          }
        }
      }
    }
  }
}
