@use "../../../scss/abstracts/" as *;

.facture-Wrrap {
  padding: 20px 20px 10px;
  border-radius: 20px;
  border: 1px solid $secondaryColor;
  @media (max-width: 576px) {
    padding: 20px 15px 10px;
  }
}

.titleNUm-fact {
  .titlenum-fact {
    font-size: 30px;
    line-height: 33px;
    color: $secondaryColor;
    font-family: $tt-demibold;
    display: block;
  }
  .num-fact {
    font-size: 25px;
    line-height: 30px;
    color: $black;
    font-family: $tt-medium;
    display: block;
  }
  .date-fact {
    color: #b0b0b0;
    font-size: 18px;
  }
  @media (max-width: 576px) {
    .titlenum-fact {
      font-size: 23px;
      line-height: 30px;
    }
    .num-fact {
      font-size: 16px;
      line-height: 30px;
    }
    .date-fact {
      font-size: 15px;
    }
  }
}
.Header-fact-infos {
  display: flex;
  justify-content: space-between;
  .left-infos-fact {
    width: 48%;
  }
  .right-infos-fact {
    width: 48%;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    .left-infos-fact {
      width: 100%;
      margin-bottom: 15px;
    }
    .right-infos-fact {
      width: 100%;
    }
  }
}
.box-fact-infos {
  background: #f5f5f5;
  box-shadow: inset 0px 4px 5px 0px lighten($black, 72);
  color: $black;
  border-radius: 40px;
  padding: 25px 20px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  height: 100%;

  h3 {
    font-size: 22px;
    text-transform: uppercase;
    line-height: 22px;
    font-family: $tt-demibold;
    color: $black;
    @media (max-width: 576px) {
      font-size: 20px;
    }
  }
  .ligne-infos {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: black;
    font-family: $tt-medium;
    margin: 0px 0 6px;
    display: flex;
    @media (max-width: 576px) {
      font-size: 15px;
      line-height: 18px;
    }
  }
  .label-title {
    color: #b0b0b0;
    font-family: $tt-regular;
    display: inline-block;
    margin: 0 5px 0 0;
    min-width: 85px;
  }
  &.box-secondary {
    background: lighten($secondaryColor, 35);
    box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
    .label-title {
      color: $secondaryColor;
    }
  }
  &.box-primary {
    background: lighten($primaryColor, 35);
    box-shadow: inset 0px 4px 6px 0px lighten($primaryColor, 25);
    .label-title {
      color: $primaryColor;
    }
  }

  &.box-danger {
    background: lighten($orange, 35);
    box-shadow: inset 0px 4px 6px 0px lighten($orange, 15);
    .label-title {
      color: $orange;
    }
  }
  &.box-warning {
    background: lighten($red, 45);
    box-shadow: inset 0px 4px 6px 0px lighten($red, 25);
    .label-title {
      color: $red;
    }
  }
  @media (max-width: 576px) {
    margin-bottom: 15px;
    padding: 20px 15px;
  }
}
.infos-additionnels {
  padding: 20px 0 0;
}
.text-added {
  background: $white;
  box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
  border: 2px dashed $secondaryColor;
  border-radius: 23px;
  padding: 20px 15px;
  .label-text-added {
    display: inline-block;
    font-size: 22px;
    line-height: 22px;
    font-family: $tt-demibold;
    color: $secondaryColor;
    @media (max-width: 576px) {
      font-size: 20px;
    }
  }
}
.Header-fact-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 40px 10px;
  .left-head-fact {
    width: 50%;
    justify-content: start;
    display: flex;
    align-items: start;
    flex-direction: column;
  }
  .right-head-fact {
    width: 50%;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    @media (max-width: 767px) {
      align-items: flex-end;
    }
    @media (max-width: 576px) {
      .form__button {
        font-size: 15px;
        padding: 0 10px;
      }
    }
    .invoice-logo {
      background-color: $mainBg;
      border-radius: $borderRadius;
      height: 100%;
      width: 150px !important;
      position: relative;
      @media (max-width: 576px) {
        height: 70px;
        width: 70px !important;
      }
      .bgCercle {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 991px) {
          display: block;
        }
      }

      img {
        --size: 120px;
        max-width: var(--size);
        height: auto;
        border-radius: 50%;
        overflow: hidden;
        max-width: 120px;
        height: 120px;
        object-fit: cover;
        @media (max-width: 576px) {
          height: 55px;
          max-width: 55px !important;
        }
      }
    }
  }
  @media (max-width: 767px) {
    justify-content: flex-end;
    padding: 10px 0;
  }
}
.table-fact {
  padding: 25px 0 0;
  min-height: 300px;
  .table > :not(:first-child) {
    border-top: none;
  }
  .table {
    thead tr {
      th {
        background: $secondaryColor;
        border-bottom: none;
        color: $white;
        font-size: 14px;
        font-family: $tt-medium;
        padding: 0.7rem 0.5rem;
        // box-shadow: inset 0px 4px 6px 0px lighten($secondaryColor, 25);
        &:first-child {
          border-radius: 50px 0 0 50px;
        }
        &:last-child {
          border-radius: 0 50px 50px 0;
        }
      }
    }
    td {
      font-size: 14px;
      font-family: $tt-medium;
      padding: 0.7rem 0.5rem;
      input {
        @media (max-width: 1199px) {
          min-width: 100px;
        }
      }
    }
    @media (max-width: 767px) {
      th,
      td {
        white-space: nowrap;
      }
    }
  }
}
.resultat-table {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0;
  border-top: 2px solid $secondaryColor;
}
.item-totat {
  font-family: $tt-medium;
  text-align: right;
  .label-total {
    color: $black;
    font-size: 14px;
    display: inline-block;
    margin-right: 10px;
  }
  .num-total {
    color: $black;
    font-size: 22px;
    font-family: $tt-demibold;
  }
  &.total-ttc span {
    color: $secondaryColor;
  }
}
.leaglFooter {
  p {
    width: 100%;
    border: none;
    padding: 5px;
    font-size: 14px;
    color: darken($default, 50);
    text-align: center;
    margin: 0;
  }
}
.footer-fact {
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  span {
    color: $secondaryColor;
  }
}
