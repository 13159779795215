@media (min-width: 576px) {
  .modal-dialog {
    max-width: 900px;
  }
}

.with_html {
  @media (min-width: 576px) {
    max-width: 900px;
  }

  .modal-content {
    height: 520px;

    #react-doc-viewer {
      height: 100%;
    }
  }
}

.image-downloader {
  text-align: end;

  a {
    color: #000 !important;
    text-decoration: none !important;
    margin: 0 5px !important;
  }
}