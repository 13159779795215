@use "../../scss/abstracts/" as *;

.section-import {
  .card {
    padding: 50px 15px;
  }
}

.card-body {
  .transactions-import-actions{
    display: flex;
    justify-content: end;
  }
  h3 {
    color: #b0b0b0;
    font-family: $tt-demibold;
    margin-bottom: 35px;
  }

  .file-uploader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 0;

    h3 {
      border-bottom: 2px dashed $default;
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      margin: 15px 10px;
    }
  }

  .action-states {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .list-Files {
      margin-bottom: 10px;
    }
  }

  .status-messages {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  .import-transactions-wrapper {
    display: flex;
    justify-content: center;
    align-items:center;
    .import-transactions,.custom-select {
      width: 50%;
      height: 60px;
      justify-content: space-between;
      margin: 5px 10px;
    }
  }
}

.transaction-add-nb{
  color: $red;
  font-weight: bold;
}

.manual-tr-type{
  .custom-switch{
    margin-top: 0 !important;
    .label-enc{
      margin-left: 0 !important;
      padding-left: 0 !important;
    }
    .react-switch-label{
      border-color: $primaryColor;
      .react-switch-button{
        background : $primaryColor
      }
    }
  }
  
}