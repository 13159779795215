@use "../../../scss/abstracts/" as *;

.page {
  position: relative;
}
.btn-creat-admin {
  position: absolute;
  top: -82px;
  right: 75px;
  min-width: 240px;
  min-height: 54px !important;
  svg {
    margin: 0 15px 0 0 !important;
  }
}
