@use "../../scss/abstracts/" as *;
@use "sass:math";
.card-dash-depense {
  background: $white;
  border-radius: 40px;
  padding: 25px;
  color: $black;
  position: relative;
  margin-bottom: 15px;
  @media (max-width: 1350px) {
    padding: 15px;
  }
  .box-inner-card {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    h4 {
      color: #6f678a;
      font-size: 1.1rem;
    }
  }
  .card-icon {
    // background: rgba(255, 255, 255, 0.35);
    // border: 2px solid rgba(255, 255, 255, 0.63);
    border-radius: 25px;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 70px;
      height: auto;
    }
    @media (max-width: 1350px) {
      width: 60px;
      height: 60px;
      border-radius: 20px;
      img {
        max-width: 50px; 
      }
    }
  }
  .card-texte {
    width: calc(100% - 90px);
    padding-left: 15px;
    p {
      text-align: right;
      color: #6f678a;
      font-size: 1rem;
    }
    @media (max-width: 1350px) {
      width: calc(100% - 60px);
      p{
        margin-bottom: 0;
      }
    }
  }
  .card-price {
    font-size: 2.1rem;
    line-height: 35px;
    font-family: $tt-demibold;
    color: $black;
    @media (max-width: 1350px) {
      font-size: 2rem;
    }
    @media (max-width: 576px) {
      font-size: 1.65rem;
      line-height: 30px;
    }
  }
  .etat-value-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    img,
    svg {
      position: relative;
      top: 2px;
      max-height: 23px;
      width: auto;
    }
    .etat-percent {
      color: rgba(255, 255, 255, 0.65);
      display: inline-block;
      padding: 0 0 0 5px;
    }
  }

  .progress {
    display: flex;
    height: 8px;
    overflow: visible;
    font-size: 0.75rem;
    background-color: transparent;
    border-radius: 15px;
    width: calc(100% - 60px);
    position: relative;
  }
  .progress-bar {
    border-radius: 15px;
    .progress-percent {
      position: absolute;
      right: -65px;
      font-family: $tt-demibold;
      font-size: 1rem;
      background: #fff;
      border-radius: 5px;
      display: inline-block;
      padding: 3px;
    }
  }
  &.card-purple {
    box-shadow: 5px 10px 10px lighten($purple, 40);
    .card-icon {
      background-image: linear-gradient(
        to top,
        #795be0,
        #886ce7,
        #967cee,
        #a58df5,
        #b39efb
      );
      box-shadow: -5px 5px 30px lighten($purple, 20);
    }
    .progress-bar {
      background-color: $purple;
      .progress-percent {
        color: $purple;
      }
    }
  }
  &.card-primary {
    box-shadow: 5px 10px 10px lighten($primaryColor, 40);
    .card-icon {
      background-image: linear-gradient(
        to right,
        #62d1ba,
        #53c6ae,
        #44bca2,
        #34b196,
        #1fa78a
      );
      box-shadow: -5px 5px 30px lighten($primaryColor, 20);
    }
    .progress-bar {
      background-color: $primaryColor;
      .progress-percent {
        color: $primaryColor;
      }
    }
  }
  &.card-secondary {
    box-shadow: 5px 10px 10px lighten($secondaryColor, 40);
    .card-icon {
      background-image: linear-gradient(
        to bottom,
        #4fadd4,
        #46a5cc,
        #3d9cc4,
        #3494bd,
        #2a8cb5
      );
      box-shadow: -5px 5px 30px lighten($secondaryColor, 20);
    }
    .progress-bar {
      background-color: $secondaryColor;
      .progress-percent {
        color: $secondaryColor;
      }
    }
  }
  &.card-danger {
    box-shadow: 5px 10px 10px lighten($red, 45);
    .card-icon {
      background-image: linear-gradient(
        to right,
        #f68578,
        #f57465,
        #f36351,
        #ef503c,
        #eb3a27
      );
      box-shadow: -5px 5px 30px lighten($red, 20);
    }
    .progress-bar {
      background-color: $red;
      .progress-percent {
        color: $red;
      }
    }
  }
  &.card-warning {
    box-shadow: 5px 10px 10px lighten($orange, 50);
    .card-icon {
      background-image: linear-gradient(
        to right,
        #ffcc54,
        #fec24d,
        #feb946,
        #fdaf40,
        #fca53b
      );
      box-shadow: -5px 5px 30px lighten($orange, 20);
    }
    .progress-bar {
      background-color: $orange;
      .progress-percent {
        color: $orange;
      }
    }
  }
}
