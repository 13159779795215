.bridgeItem-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 3vh;
  
    .bridgeItem-item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media (min-width: 500px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 991px) {
      grid-template-columns: repeat(4, 1fr);
    }
    .bankImg-item {
      height: 40px;
      width: auto;
      @media (max-width: 500px) {
        display: none;
      }
    }
  }
  