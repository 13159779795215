.iban-edit-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  .cell-checkbox {
    display: flex;
    flex-direction: column;
    label {
        margin-bottom: 0.8rem;
    }
    .check-box{
        text-align: center;
    }
  }
}
